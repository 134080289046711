import React, { useState } from 'react';
import './AmbassadorPage.css';

function AmbassadorPage() {
    const [formData, setFormData] = useState({
        name: '',
        prenome: '',
        phone: '',
        email: '',
        city: '',
    });

    const [showOtpPage, setShowOtpPage] = useState(false); // Toggle between forms
    const [otp, setOtp] = useState('');
    const [generatedOtp, setGeneratedOtp] = useState(''); // Store generated OTP

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Ambassador Information:', formData);

        try {
            const response = await fetch('https://lahalak.sa/backend/AmbassadorB/Sign_Up.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (result.status === 'success') {
                console.log('OTP Sent Successfully:', result);
                setGeneratedOtp(result.otp); // Store the OTP from the backend
                setShowOtpPage(true); // Show the OTP input form
            } else {
                alert(result.message || 'Failed to send OTP.');
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            alert('Failed to send OTP. Please try again.');
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();

        if (otp !== generatedOtp) {
            alert('Invalid OTP. Please try again.');
            return;
        }

        try {
            const response = await fetch('https://lahalak.sa/backend/AmbassadorB/Verify_Otp.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ phone: formData.phone, otp }),
            });

            const result = await response.json();
            if (result.status === 'success') {
                alert('OTP Verified Successfully!');
                console.log('OTP Verified:', result);
                // Proceed to the next step or show success message
            } else {
                alert(result.message || 'Failed to verify OTP.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            alert('Failed to verify OTP. Please try again.');
        }
    };

    return (
        <div className="ambassador-container">
            {!showOtpPage ? (
                <div className="ambassador-box">
                    <h1>Ambassador Information</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter your name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="prenome">Prenome:</label>
                            <input
                                type="text"
                                id="prenome"
                                name="prenome"
                                placeholder="Enter your prenome"
                                value={formData.prenome}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone Number:</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                placeholder="Enter your phone number"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City:</label>
                            <input
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Enter your city"
                                value={formData.city}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button">
                            Submit
                        </button>
                    </form>
                </div>
            ) : (
                <div className="otp-box">
                    <h1>Enter OTP</h1>
                    <form onSubmit={handleOtpSubmit}>
                        <div className="form-group">
                            <label htmlFor="otp">OTP:</label>
                            <input
                                type="text"
                                id="otp"
                                name="otp"
                                placeholder="Enter the OTP sent to your phone"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button">
                            Verify OTP
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
}

export default AmbassadorPage;
