// FinancePage.js
import React from 'react';

function FinancePage() {
  return (
    <div>
      <h1>Finance Dashboard</h1>
      <p>Welcome to the Finance section of the dashboard. Here you can manage and track all financial data and transactions.</p>
      <ul>
        <li>View Financial Reports</li>
        <li>Process Payments</li>
        <li>Manage Budgets</li>
        <li>Expense Tracking</li>
      </ul>
    </div>
  );
}

export default FinancePage;
