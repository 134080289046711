import React, { useState } from 'react';
import './PendingApprovalPage.css';

function PendingApprovalPage() {
  const [refreshing, setRefreshing] = useState(false);

  // Simulated refresh to check the approval status
  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        alert('Session expired. Please log in again.');
        window.location.href = '/';
        return;
      }

      const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/verify_approval.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      if (data.status === 'approved') {
        alert('Your account has been approved! Redirecting...');
        window.location.href = '/dashboard';
      } else if (data.status === 'pending') {
        alert('Your account is still under review.');
      } else {
        alert('Error checking approval status. Please try again.');
      }
    } catch (error) {
      console.error('Error refreshing approval status:', error);
      alert('Unable to refresh status. Please try again later.');
    } finally {
      setRefreshing(false);
    }
  };

  return (
    <div className="pending-approval-container">
      <div className="pending-approval-card">
        <h1 className="pending-approval-title">Account Approval Pending</h1>
        <p className="pending-approval-message">
          Your account is currently under review by the administrator. This process ensures all users meet our platform's standards.
        </p>
        <p className="pending-approval-message">
          You will receive a notification once your account is approved. Thank you for your patience.
        </p>
        <div className="pending-approval-actions">
          <button 
            className="pending-approval-button" 
            onClick={handleRefresh} 
            disabled={refreshing}
          >
            {refreshing ? 'Checking Status...' : 'Refresh Status'}
          </button>
          <button 
            className="pending-approval-contact-button" 
            onClick={() => window.location.href = 'mailto:support@lahalak.sa'}
          >
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
}

export default PendingApprovalPage;
