import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function DashboardPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [isApproved, setIsApproved] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        console.log('Starting authentication check...');
        const token = localStorage.getItem('authToken');
        console.log('Retrieved Token:', token);

        if (!token) {
          console.warn('No token found, redirecting to login.');
          setIsAuthenticated(false);
          navigate('/');
          return;
        }

        const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/verify_jwt.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error(`Server error! Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Authentication Response:', data);

        if (data.status === 'success') {
          const { role, isApproved } = data.data || {};
          console.log('Extracted Role:', role);
          console.log('Extracted Approval Status:', isApproved);

          if (role && isApproved !== undefined) {
            setIsAuthenticated(true);
            setUserRole(role);
            setIsApproved(isApproved);

            // Redirect to Pending Approval page if not approved
            if (!isApproved) {
              console.warn('User not approved, redirecting to Pending Approval page.');
              navigate('/pending-approval');
              return;
            }

            // Redirect to role-specific page
            navigateToRolePage(role);
          } else {
            console.error('Missing role or approval status in response.');
            throw new Error('Invalid authentication data.');
          }
        } else {
          console.warn('Authentication failed, redirecting to login.');
          throw new Error('Authentication failed.');
        }
      } catch (error) {
        console.error('Error during authentication:', error.message || error);
        setIsAuthenticated(false);
        navigate('/');
      } finally {
        setLoading(false);
        console.log('Authentication check complete.');
      }
    };

    checkAuthentication();
  }, [navigate]);

  const navigateToRolePage = (role) => {
    console.log('Navigating based on role:', role);

    const rolePages = {
      'Top Management': '/top-management',
      'Content Manager': '/content-manager',
      'Data Entry': '/data-entry',
      'Finance': '/finance',
      'Customer Management': '/customer-management',
      'Marketing': '/marketing',
      'Ambassador': '/ambassador',
    };

    const targetPage = rolePages[role];
    if (targetPage) {
      console.log('Redirecting to:', targetPage);
      navigate(targetPage);
    } else {
      console.error('Unknown role:', role);
      navigate('/dashboard'); // Redirect to a default page if the role is unrecognized
    }
  };

  if (loading) {
    console.log('Loading authentication state...');
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    console.log('User not authenticated, redirecting to pending-approval.');
    navigate('/pending-approval'); // Redirect to a default page if the role is unrecognized

    return (
      <div className="unauthorized-container">
        <p>You are not authorized to access this page. Redirecting to login...</p>
      </div>
    );
  }

  if (!isApproved) {
    console.log('User not approved, displaying Pending Approval message.');
    return (
      <div className="pending-approval-container">
        <h2>Access Pending</h2>
        <p>Your account is pending approval. Please wait for confirmation from the administrator.</p>
        <button onClick={() => window.location.reload()} className="retry-button">
          Check Again
        </button>
      </div>
    );
  }

  console.log('Authenticated and approved, rendering dashboard.');
  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>
      <p>Welcome to the Lahalak Dashboard!</p>
      <p>Redirecting to your role-specific page...</p>
    </div>
  );
}

export default DashboardPage;
