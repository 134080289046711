import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar.js'; // Import Navbar
import LoginPage from './pages/LoginPage.js';
import SignupPage from './pages/SignupPage.js';
import DashboardPage from './pages/DashboardPage.js';
import AmbassadorPage from './pages/AmbassadorPage.js';
import TopManagementPage from './pages/TopManagementPage.js';
import ContentManagerPage from './pages/ContentManagerPage.js';
import FinancePage from './pages/FinancePage.js';
import CustomerManagementPage from './pages/CustomerManagementPage.js';
import MarketingPage from './pages/MarketingPage.js';
import PendingApprovalPage from './pages/PendingApprovalPage.js'; // Import the new PendingApprovalPage
import ProtectedRoute from './components/ProtectedRoute.js'; // Import ProtectedRoute
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Routes>
          {/* Unprotected Routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute requiresApproval={true}>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/top-management"
            element={
              <ProtectedRoute allowedRoles={["Top Management"]}>
                <TopManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/content-manager"
            element={
              <ProtectedRoute allowedRoles={["Content Manager"]}>
                <ContentManagerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <ProtectedRoute allowedRoles={["Finance"]}>
                <FinancePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer-management"
            element={
              <ProtectedRoute allowedRoles={["Customer Management"]}>
                <CustomerManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/marketing"
            element={
              <ProtectedRoute allowedRoles={["Marketing"]}>
                <MarketingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/ambassador"
            element={
              <ProtectedRoute allowedRoles={["Ambassador"]}>
                <AmbassadorPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pending-approval"
            element={
              <ProtectedRoute requiresApproval={false}>
                <PendingApprovalPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;