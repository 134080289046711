import React from 'react';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, allowedRoles = [], requiresApproval = false }) {
  const token = localStorage.getItem('authToken');
  const userRole = localStorage.getItem('userRole');
  const isApproved = localStorage.getItem('isApproved') === 'true'; // Retrieve `isApproved` status

  console.log('Token:', token);
  console.log('UserRole:', userRole);
  console.log('AllowedRoles:', allowedRoles);
  console.log('IsApproved:', isApproved);

  // Redirect to login if no token is found
  if (!token) {
    console.warn('No token found, redirecting to login.');
    return <Navigate to="/" />;
  }

  try {
    const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload
    const { exp } = payload;

    // Check if the token has expired
    if (Date.now() >= exp * 1000) {
      console.warn('Token expired, redirecting to login.');
      localStorage.removeItem('authToken');
      localStorage.removeItem('userRole');
      localStorage.removeItem('isApproved');
      alert('Session expired. Please log in again.');
      return <Navigate to="/" />;
    }

    // Redirect to pending approval if `requiresApproval` is true and the user is not approved
    if (requiresApproval && !isApproved) {
      console.log('User not approved, redirecting to pending approval page.');
      return <Navigate to="/pending-approval" />;
    }

    // Check if the user's role matches the allowed roles
    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
      console.warn(`Unauthorized role (${userRole}), redirecting to dashboard.`);
      return <Navigate to="/" />;
    }
  } catch (error) {
    console.error('Error decoding token:', error);
    localStorage.removeItem('authToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('isApproved');
    return <Navigate to="/" />;
  }

  console.log('Access granted, rendering children.');
  return children;
}

export default ProtectedRoute;
