import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LoginPage.css';
import logo from '../assets/loba2.png';

function LoginPage() {
  const navigate = useNavigate();
  const [id, setId] = useState(''); // State for ID
  const [password, setPassword] = useState(''); // State for password
  const [error, setError] = useState(''); // State for error message
  const [loading, setLoading] = useState(false); // State for loading indicator

  // Format phone number to include country code
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith('0')) {
      return `966${phone.slice(1)}`;
    }
    return phone;
  };

  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state
    setLoading(true); // Show loading spinner

    const formattedId = formatPhoneNumber(id); // Format the ID
    console.log('Formatted ID:', formattedId); // Debugging formatted ID

    try {
      const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: formattedId, password }),
      });

      console.log('Request Payload:', { id: formattedId, password }); // Debugging request payload

      if (!response.ok) {
        console.error('HTTP Error:', response.status); // Debugging HTTP error
        throw new Error(`Server error! Status: ${response.status}`);
      }

      const data = await response.json(); // Parse JSON response
      console.log('Response Data:', data); // Debugging parsed response

      if (data.status === 'success') {
        localStorage.setItem('authToken', data.token);

        // Safely extract role, approval, and verification status
        const role = data.data?.role || '';
        const isApproved = data.data?.approved || 0; // Use approved value
        const isVerified = data.data?.is_verified || 0;

        console.log('Stored UserRole:', role);
        console.log('Approval Status:', isApproved);
        console.log('Verification Status:', isVerified);

        localStorage.setItem('userRole', role);
        localStorage.setItem('isApproved', isApproved);

        // Navigate based on approval and verification status
        if (isVerified === 1 && isApproved === 1) {
          console.log(`User approved, redirecting to ${role} page.`);
          navigate(`/${role.toLowerCase().replace(/\s+/g, '-')}`); // Redirect to role-specific page
        } else {
          console.log('User not approved or not verified, redirecting to pending-approval.');
          navigate('/pending-approval');
        }
      } else {
        setError(data.message || 'Invalid ID or password.'); // Display server error message
      }
    } catch (err) {
      console.error('Error during login:', err.message || err); // Debugging error
      setError(err.message || 'An error occurred. Please try again.');
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img
          src={logo} // Replace with the actual logo file path
          alt="Logo"
          className="login-logo"
        />
        <h1 className="login-title">لوحة التحكم</h1>
        <h2 className="login-subtitle">Dashboard</h2>
        <form onSubmit={handleLogin}>
          {/* Input for ID */}
          <div className="input-container">
            <label className="input-label">
              الرقم التعريفي<br />
              <span>ID</span>
            </label>
            <input
              type="text"
              className="login-input"
              placeholder="Enter ID"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
          </div>

          {/* Input for Password */}
          <div className="input-container">
            <label className="input-label">
              كلمة السر<br />
              <span>Password</span>
            </label>
            <input
              type="password"
              className="login-input"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="current-password"
            />
          </div>

          {/* Submit Button */}
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Loading...' : 'التالي'}
          </button>

          {/* Error Message */}
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
