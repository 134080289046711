import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authToken'); // Clear token
        navigate('/signup'); // Redirect to signup
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/">Lahalak</Link>
            </div>
            <ul className="navbar-links">
                <li>
                    <Link to="/">Login</Link>
                </li>
                <li>
                    <Link to="/signup">Sign Up</Link>
                </li>
                <li>
                    <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
