import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import './SignupPage.css';
import logo from '../assets/loba2.png';

function SignupPage() {
    const [role, setRole] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [countryCode, setCountryCode] = useState('+966'); // Default to Saudi Arabia
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false); // Track if OTP is generated
    const [generatedOtp, setGeneratedOtp] = useState(''); // Store the generated OTP for debugging
    const [otpError, setOtpError] = useState(''); // Error for OTP
    const [formError, setFormError] = useState(''); // General form error
    const [otpSuccess, setOtpSuccess] = useState(false); // OTP verification success
    const navigate = useNavigate(); // Navigation

    const handleRoleChange = (e) => {
        setRole(e.target.value);
    };

    const validatePhoneNumber = (number) => {
        const formattedNumber = `${countryCode.replace('+', '')}${number.replace(/^0/, '')}`;
        return /^[0-9]{10,12}$/.test(formattedNumber); // Validate length
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setFormError(''); // Reset form error

        // Check for empty fields
        if (!name || !email || !password || !phoneNumber || !role) {
            setFormError('All fields are required.');
            return;
        }

        // Validate password length
        if (password.length < 6) {
            setFormError('Password must be at least 6 characters long.');
            return;
        }

        // Validate phone number format
        const formattedPhoneNumber = `${countryCode.replace('+', '')}${phoneNumber.replace(/^0/, '')}`;
        if (!validatePhoneNumber(phoneNumber)) {
            setFormError('Invalid phone number format.');
            return;
        }

        const userData = {
            name,
            email,
            password,
            role,
            phone: formattedPhoneNumber,
        };

        try {
            const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/Sign_Up.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            console.log('Response data:', data); // Debugging response

            if (data.status === 'success') {
                alert('User registered successfully. Check your OTP for testing purposes.');
                setIsOtpSent(true); // Show the OTP input field
                setGeneratedOtp(data.otp); // Store the OTP from the server for display
            } else {
                setFormError(data.message || 'Failed to sign up. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setFormError('An error occurred while signing up.');
        }
    };

    const handleVerifyOtp = async () => {
        setOtpError(''); // Reset OTP error
    
        if (!otp) {
            setOtpError('Please enter the OTP.');
            console.log("No OTP entered."); // Log if OTP is missing
            return;
        }
    
        // Format the phone number to remove leading 0 and ensure "+" is not included
        const formattedPhone = `${countryCode.replace('+', '')}${phoneNumber.replace(/^0/, '')}`;
    
        console.log("Raw phone number entered by user:", phoneNumber); // Log raw user input
        console.log("Country code:", countryCode); // Log country code
        console.log("Formatted phone number sent to backend:", formattedPhone); // Log formatted phone number
        console.log("OTP entered by user:", otp); // Log user-entered OTP
    
        try {
            const response = await fetch('https://lahalak.sa/backend/AmbassadorB/auth/checked.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone: formattedPhone, // Send the correctly formatted phone number without "+"
                    otp, // User-entered OTP
                }),
            });
    
            console.log("Request sent to backend:", {
                phone: formattedPhone,
                otp,
            }); // Log the entire request payload
    
            const data = await response.json();
    
            console.log("Response from backend:", data); // Log the backend response
    
            if (data.status === 'success') {
                alert('OTP verified successfully!');
                navigate('/'); // Redirect to dashboard on success
            } else {
                console.log("Error message from backend:", data.message); // Log backend error message
                setOtpError(data.message || 'Invalid OTP. Please try again.');
            }
        } catch (error) {
            console.error("Error during OTP verification:", error); // Log the error object
            setOtpError('An error occurred. Please try again later.');
        }
    };
    
    
    
    return (
        <div className="signup-container">
            <div className="signup-box">
                <img src={logo} alt="Logo" className="signup-logo" />
                <h1>Sign Up</h1>
                <form onSubmit={handleSignUp}>
                    <div className="role-select">
                        <label htmlFor="role">Select your role:</label>
                        <select id="role" value={role} onChange={handleRoleChange} required>
                            <option value="" disabled>
                                -- Select a Role --
                            </option>
                            <option value="Top Management">Top Management</option>
                            <option value="Content Manager">Content Manager</option>
                            <option value="Data Entry">Data Entry</option>
                            <option value="Finance">Finance</option>
                            <option value="Customer Management">Customer Management</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Ambassador">Ambassador</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder="Name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="phone-input">
                        <select
                            value={countryCode}
                            onChange={(e) => setCountryCode(e.target.value)}
                            required
                        >
                            <option value="+966">+966 (Saudi Arabia)</option>
                            <option value="+971">+971 (UAE)</option>
                            <option value="+965">+965 (Kuwait)</option>
                            <option value="+963">+963 (Syria)</option>
                            <option value="+967">+967 (Yemen)</option>
                            <option value="+961">+961 (Lebanon)</option>
                            <option value="+20">+20 (Egypt)</option>
                            <option value="+249">+249 (Sudan)</option>
                        </select>
                        <input
                            type="tel"
                            placeholder="Phone Number"
                            required
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>

                    {isOtpSent && (
                        <>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                required
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button type="button" onClick={handleVerifyOtp}>
                                Verify OTP
                            </button>
                            {otpError && <p className="error-message">{otpError}</p>}
                        </>
                    )}

                    {formError && <p className="error-message">{formError}</p>}
                    {!isOtpSent && <button type="submit">Sign Up</button>}
                </form>
                {otpSuccess && <p>Sign-up complete! Redirecting...</p>}
                <p>
                    Already have an account? <a href="/">Login</a>
                </p>
            </div>
        </div>
    );
}

export default SignupPage;
