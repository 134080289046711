// TopManagementPage.js
import React from 'react';
import './TopManagementPage.css'; // Import the CSS file

function TopManagementPage() {
  return (
    <div className="top-management-container">
      <h1>Top Management Dashboard</h1>
      <p>Welcome to the Top Management section of the dashboard! Here, you can access key performance metrics, manage high-level strategies, and oversee overall business performance.</p>
      
      {/* Key Metrics Section */}
      <section>
        <h2>Key Metrics</h2>
        <ul>
          <li>Total Revenue: $5,000,000</li>
          <li>Active Projects: 12</li>
          <li>Employee Engagement: 87%</li>
          <li>Customer Satisfaction: 92%</li>
        </ul>
      </section>
      
      {/* Strategic Goals Section */}
      <section>
        <h2>Strategic Goals</h2>
        <ol>
          <li>Expand market share by 15% this year.</li>
          <li>Implement new operational efficiency tools by Q3.</li>
          <li>Increase employee retention to 95%.</li>
          <li>Launch three new products in the next two quarters.</li>
        </ol>
      </section>
      
      {/* Notifications Section */}
      <section>
        <h2>Notifications</h2>
        <div className="notification">
          <p><strong>Meeting:</strong> Quarterly Review on June 15, 2024.</p>
        </div>
        <div className="notification">
          <p><strong>Deadline:</strong> Annual Budget submission by June 30, 2024.</p>
        </div>
        <div className="notification">
          <p><strong>Event:</strong> Leadership Summit on July 20, 2024.</p>
        </div>
      </section>
      
      {/* Team Highlights */}
      <section>
        <h2>Team Highlights</h2>
        <div className="highlight-box">
          <p><strong>Sales Team:</strong> Exceeded targets by 20% last quarter.</p>
        </div>
        <div className="highlight-box">
          <p><strong>Marketing Team:</strong> Successful launch of the "Lahalak Campaign" with over 500,000 impressions.</p>
        </div>
        <div className="highlight-box">
          <p><strong>Operations Team:</strong> Reduced operational costs by 10% through process optimization.</p>
        </div>
      </section>
      
      {/* Quick Links */}
      <section>
        <h2>Quick Links</h2>
        <ul>
          <li><a href="/reports">View Reports</a></li>
          <li><a href="/meetings">Schedule Meetings</a></li>
          <li><a href="/teams">Manage Teams</a></li>
          <li><a href="/performance">Review Performance</a></li>
        </ul>
      </section>
    </div>
  );
}

export default TopManagementPage;
