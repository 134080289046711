import React, { useEffect, useState } from 'react';
import './CustomerManagementPage.css';

function CustomerManagementPage() {
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [collapsedDates, setCollapsedDates] = useState({}); // Track collapsed state for each date

  // Toggle collapse state for a specific date
  const toggleCollapse = (date) => {
    setCollapsedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // Group orders by date
  const groupedOrders = orders.reduce((acc, order) => {
    const date = order.date.split(' ')[0]; // Extract the date part (e.g., "YYYY-MM-DD")
    if (!acc[date]) acc[date] = [];
    acc[date].push(order);
    return acc;
  }, {});

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await fetch('https://lahalak.sa/backend/AmbassadorB/getData/getData.php');

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.order_date);
          const dateB = new Date(b.order_date);
          return dateB - dateA;
        });

        setCustomerData(sortedData);
      } catch (err) {
        console.error('Error fetching customer data:', err);
        setError('Failed to fetch customer data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerData();
  }, []);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://lahalak.sa/backend/Mobile/fetch_database_info.php');
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // Sort orders by date in descending order
        const sortedOrders = data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setOrders(sortedOrders); // Update the orders state with sorted data
      } catch (err) {
        console.error('Error fetching orders:', err);
        setError('Failed to fetch orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <p>Loading customer data...</p>;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="customer-management-container">
      <h1 className="customer-management-title">Customer Management Dashboard</h1>
      <p className="customer-management-description">
        Welcome to the Customer Management section of the dashboard. Here, you can view and manage all aspects of customer data and interactions, ensuring a seamless customer experience.
      </p>

      <CPUP customerData={customerData} />
      <div>
        <h1>Orders</h1>
        {Object.entries(groupedOrders).map(([date, ordersForDate]) => (
          <div key={date} className="order-date-group">
            <div className="order-date-label" onClick={() => toggleCollapse(date)}>
              <h3>{date}</h3>
              <span>{collapsedDates[date] ? '▼' : '▲'}</span>
            </div>
            {collapsedDates[date] && (
              <div>
                {ordersForDate.map((order) => (
                  <OrderDetails key={order.order_id} order={order} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function CPUP({ customerData }) {
  return (
    <section className="customer-management-table">
      <h2>Customers Paid Using the Platform</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Service</th>
            <th>Amount</th>
            <th>Response Message</th>
            <th>Payment Option</th>
            <th>Date</th>
            <th>Reference Number</th>
          </tr>
        </thead>
        <tbody>
          {customerData
            .filter((customer) => customer.reference_number)
            .map((customer) => (
              <tr
                key={customer.order_id}
                className={
                  customer.payment_response_message === 'Success' ? 'success-row' : ''
                }
              >
                <td>{customer.order_id}</td>
                <td>{customer.customer_name}</td>
                <td>{customer.customer_email}</td>
                <td>{customer.order_service}</td>
                <td>{customer.payment_amount}</td>
                <td>{customer.payment_response_message}</td>
                <td>{customer.payment_option}</td>
                <td>{customer.order_date}</td>
                <td>
                  <button
                    className="reference-button"
                    onClick={() => 
                      window.open(
                        `https://lahalak.sa/Authorization_letter?referenceNumber=${customer.reference_number}`,
                        '_blank' // Opens in a new tab or window
                      )
                    }
                    
                    disabled={!customer.reference_number}
                  >
                    View Letter
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
}

function OrderDetails({ order }) {
  return (
    <table className="order-details-table">
      <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
      
        <tr>
          <td><strong>Date</strong></td>
          <td>{order.date}</td>
        </tr>
        <tr>
          <td><strong>Name</strong></td>
          <td>{order.name}</td>
        </tr>
        <tr>
          <td><strong>Email</strong></td>
          <td>{order.email}</td>
        </tr>
        <tr>
          <td><strong>Service</strong></td>
          <td>{order.service}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td>{order.total}</td>
        </tr>
        <tr>
          <td><strong>Phone</strong></td>
          <td>{order.phone}</td>
        </tr>
        <tr>
          <td><strong>Reference Number</strong></td>
          <td>{order.reference_number}</td>
        </tr>
        <tr>
          <td><strong>Nationality</strong></td>
          <td>{order.nationality}</td>
        </tr>
        <tr>
          <td><strong>Map URL</strong></td>
          <td>
            <a href={order.map_url} target="_blank" rel="noopener noreferrer">
              View Map
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default CustomerManagementPage;
